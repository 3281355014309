@import "../../css/vars";

.base-answer {
  margin-top: 16px;
  align-items: flex-end;
  .base-textarea {
    width: 100%;
    textarea {
      height: 60px;
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .base-checkbox {
    height: 60px;
    padding: 12px;
    transform: translateY(-4px);
    position: relative;
    border: 1px solid $ui-gray-medium;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    input {
      height: 0.1px;
      width: 0;
      visibility: hidden;
    }
    &:after {
      content: '';
      width: 20px;
      height: 20px;
      display: inline-block;
      border-radius: 4px;
      border: 1px solid $ui-gray-medium;
    }
    &.checked:before {
      content: '';
      width: 14px;
      height: 14px;
      right: 16px;
      display: inline-block;
      border-radius: 4px;
      background-color: $link-blue;
      position: absolute;
    }
    span {
      font-weight: 100;
    }
  }
}
