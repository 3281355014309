@import '../../css/vars';
.cms-homepage {
  height: calc(100% - 182px);
  padding: 0 $l-r-content-padding;
  select {
    width: 100%;
    padding: 12px;
  }
  label {
    display: block;
  }
  label + div {
    display: flex;
    justify-content: space-between;
  }
  .chapters-list-wrapper input {
    margin-top: 8px;
    margin-bottom: 16px;
    padding: 12px 8px;
    font-size: 16px;
    display: inline-block;
    width: 46%;
  }
  .add-chapter-btn {
    background: transparent;
    color: $main-blue;
    border: none;
    font-size: 16px;
    font-family: interfaceregular, sans-serif;
  }
  .remove-chapter-btn {
    background: transparent;
    color: $main-blue;
    border: none;
    font-size: 16px;
    font-family: interfaceregular, sans-serif;
  }
  .save-chapters-btn {
    background-color: $purple;
    padding: 12px 48px;
    margin-top: 24px;
    margin-left: auto;
    border-radius: 4px;
    border: none;
    display: block;
    color: white;
    font-size: 18px;
    font-family: interfaceregular, sans-serif;
  }
  .select-search:not(.select-search--multiple) .select-search__input:hover {
    border-color: grey;
  }
  .select-search {
    border: 1px solid lightgrey;
  }
  .select-search__select {
    transform: translateY(-7px);
    border: 1px solid lightgray;
  }
  .select-search__option {
    padding: 6px;
    height: 100% !important;
  }
  .select-search__option:hover {
    background-color: var(--bmj-purple-30) !important;
  }
  .select-search__option.is-selected {
    background: $purple;
    &:hover {
      background-color: $purple !important;
    }
  }
  section.page-content {
    height: 100%;
    max-width: $max-content-area;
    margin: 0 auto;
    h2 {
      margin: 34px 0 24px;
      color: $font-color-dark;
    }
    input {
      padding: 12px 8px;
      border-radius: 4px;
      border: 1px solid lightgrey;
      width: 320px;
      font-size: 16px;
    }
    ul {
      display: flex;
      justify-content: space-between;
    }
    div.table-filters .search i {
      right: 10px;
      top: 15px;
    }
    div.type-selector {
      margin-top: 16px;
      button {
        width: 370px;
        height: 64px;
        padding-left: 22px;
        border: none;
        background-color: white;
        font-size: $copy-body;
        text-align: left;
        &.selected {
          background-color: $grey-section-background;
          font-weight: bold;
        }
      }
    }
    ul.table-header {
      padding: 8px;
      background-color: #f2f2f2;
    }
    div.table-body {
      height: 100%;
      padding-bottom: 240px;
      overflow-y: scroll;
      border-bottom: 1px solid $ui-gray-light;
      div {
        margin: 0 auto;
      }
      ul {
        padding: 10px 8px;
        border-bottom: 1px solid lightgray;
      }
      a {
        font-size: $copy-body;
        color: $link-blue;
      }
    }
    li.title {
      max-width: 680px;
      flex: 1 0 auto;
    }
    li.id {
      width: 120px;
    }
    li.locale {
      width: 60px;
    }
    li.status {
      width: 60px;
    }
  }
  .add-collection {
    right: 100px;
    bottom: 60px;
  }
}

.loading-spinner {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}
