@import "vars";

h1, h2, p {
  font-weight: 400;
  color: $font-color-dark;
}
h1 {
  font-size: 34px;
}
h2 {
  font-size: 24px;
}
input {
  font-size: 16px;
}
