@import '../../css/vars';

.course-acknowledgement {
  h2 {
    margin-bottom: 24px !important;
  }
  .base-input input {
    width: 264px;
  }
  .acknowledgement-group {
    padding: 16px 24px;
    margin-bottom: 16px;
    border-radius: 16px;
    background-color: $grey-section-background;
  }
  b span {
    font-weight: 100;
  }
}
