@import "../../css/vars";

.collection-information {
  h2 {
    margin-bottom: 24px!important;
  }
  .base-input,
  .base-textarea,
  .base-chips-group,
  .base-image-upload {
    margin-top: 16px;
  }
  .duration-options {
    > b {
      margin: 16px 0 8px;
      font-size: 16px;
      display: block;
      color: $font-color-dark;
    }
    .duration-input {
      margin-right: 24px;
    }
  }
}
