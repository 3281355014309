@import "../../css/vars";

.base-radio-button {
  padding: 0 $p-xs-md;
  margin-left: auto;
  label {
    font-size: $p-s;
    margin-right: 8px;
  }
  input {
    height: 20px;
    width: 20px;
    margin: 0;
  }
}
