@import "../../css/mixins";

.base-side-menu {
  left: 24px;
  > div {
    width: 232px;
    padding: 16px 24px;
    margin-bottom: 16px;
    @include white-rounded-box;
  }
  ul li, a {
    font-size: 16px;
    cursor: pointer;
    color: #2A6EBB;
    &:not(:last-child) {
      margin-bottom: 12px;
    }
    img {
      margin-left: 4px;
      transform: translateY(2px);
    }
  }
}
