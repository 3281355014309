@import "../../css/vars";

.base-chips-group {
  button {
    padding: 0;
    margin-left: 24px;
    border: none;
    background: transparent;
    color: #2A6EBB;
    font-size: $copy-body;
  }
}
