@import "../../css/vars";

nav.top-nav {
  width: 100%;
  padding: 0 $l-r-content-padding;
  left: 0;
  top: 0;
  position: sticky; // TODO: revert to position: fixed if regression testing not successful
  z-index: 99;
  background-color: white;
  border-bottom: 1px solid $ui-gray-light;
  --amplify-primary-color: #848484;
  --amplify-primary-tint: #ffffff;
  --amplify-primary-shade: #e0573e;
  --amplify-white: #585858!important;
  > div {
    max-width: $max-content-area;
    margin: 0 auto;
    padding: 20px 0 12px 0;
    display: flex;
    align-items: center;
  }
  .brand-logo {
    margin-right: 24px;
    transform: translateY(4px);
  }
  a {
    margin-right: 16px;
    font-size: 15px;
    color: $font-color-dark;
    &:hover {
      text-decoration: underline;
    }
  }
  /* Amplify login section css override */
  .hydrated {
    display: block;
    margin-left: auto;
    margin-right: 12px;
    opacity: 1;
    position: relative;
    &:after {
      content: "Logout";
      display: flex;
      justify-content: center;
      align-items: center;
      width: 160px;
      height: 50px;
      background-color: white;
      top: 0;
      position: absolute;
      pointer-events: none;
    }
  }
  .homepage-button {
    display: block;
    margin: 16px 0;
  }
}
