@import "../../css/vars";

.base-selector {
  label,
  select {
    color: $dark-gray;
    font-size: $copy-body;
    font-weight: 100!important;
  }
  select {
    padding-right: 12px;
    border: none;
    background: transparent;
    font-weight: bold;
  }
  img {
    margin-left: -8px;
    pointer-events: none;
    transform: translateY(-1px);
  }
}
