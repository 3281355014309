@import "../../css/vars";

.base-button {
  padding: 12px 24px;
  margin-top: 24px;
  background-color: $primary-500;
  border-radius: 4px;
  color: white;
  font-size: $copy-body;
}
