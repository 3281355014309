@import '../../css/vars.scss';
.base-textarea {
  textarea {
    height: 100px;
    resize: vertical;
  }
  .h40{
    height: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .grey{
    color: $font-color-medium;
  }
  .h150{
    height: 150px;
    padding-bottom: 15px;
  }
}
