@import "../../css/vars";

.server-error-modal {
  @media only screen and (min-width: 0) {
    .close-icon {
      top: -13px;
      right: -28px;
      transition: transform 0.1s ease-in-out;
      &:hover {
        transform: scale(1.2);
      }
    }
    p {
      margin-top: 32px;
      text-align: center;
      line-height: 28px;
      span {
        margin-top: 16px;
        line-height: 24px;
      }
    }
    .actions {
      a {
        margin-right: 24px;
        color: white;
      }
      span {
        color: $link-blue;
      }
    }
  }
}
