@import "../../css/vars";

.duration-input {
  height: 48px;
  display: inline-block;
  background-color: $grey-section-background;
  border: 1px solid $ui-gray-medium;
  border-radius: 4px;
  input {
    width: 40px;
    height: 100%;
    margin-top: 0!important;
    text-align: center;
    border: none!important;
    background-color: white;
  }
  span {
    padding: 0 8px;
    color: $font-color-medium;
  }
}
