div.base-modal {
  animation-name: fadeIn;
  animation-duration: 200ms;
  z-index: 9999;
  @media only screen and (min-width: 0) {
  	background-color: rgba(0, 0, 0, 0.8);
  }
}


