@import "../../css/vars";

.key-details-section {
  header {
    margin-bottom: 24px;
    .status-selector {
      margin: 0 18px 0 26px;
    }
    select {
      border: none;
      -webkit-appearance: none;
      -moz-appearance: none;
    }
  }

  label[for="title"] textarea {
    padding-bottom: 12px;
    resize: vertical;
  }

  .unique-updated {
    input {
      padding: 12px 0 12px 16px;
      &[disabled] {
        width: 120px;
      }
    }
    button {
      padding: 0;
      margin-top: 8px;
      background-color: transparent;
      border: none;
      color: $link-blue;
      font-size: 16px;
      img {
        margin-right: 8px;
      }
    }
    .copy-id-button {
      i {
        opacity: 0;
        transform: translate(8px, 2px);
        &.id-copied {
          opacity: 1!important;
        }
      }
    }
  }

  .subscriptions-chips {
    .base-chip-selected {
      color: $primary-600!important;
    }
  }

  .p-multiselect {
    width: 100%;
    margin-top: 8px;
  }
  .p-multiselect-token {
    border: 1px solid $primary-600;
    background-color: #F3F0F9!important;
    color: $primary-600!important;
    font-weight: bold;
    border-radius: 12px!important;
  }
  .react-datepicker-wrapper input {
    padding-right: 12px;
  }
}
