.courses-included-section {
  h2 {
    margin-bottom: 28px!important;
  }
  > button {
    margin-top: 16px;
    &[disabled] {
      opacity: 0.5;
      cursor: default;
    }
  }
}
