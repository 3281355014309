@import "../../css/vars";

.base-chip {
  padding: 8px 12px;
  border-radius: 12px;
  border: 1px solid $ui-gray-medium;
  font-size: $copy-body;
  margin-right: 6px;
  margin-top: 8px;
}

.base-chip-selected {
  border: 1px solid $primary-600;
  background-color: #F3F0F9;
  color: $primary-600;
  font-weight: bold;
}
