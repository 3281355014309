@import "../../css/vars";

.organisation-list {
  max-width: $max-content-area;
  margin: 0 auto;
  padding: 0 $l-r-content-padding;
  @media only screen and (min-width: $max-content-area) {
    padding: 0;
  }
  button.primary-button {
    margin: 24px 0 0 0;
  }
  .organisation table {
    border: 1px solid #e7e1f4;
    margin: 0 0 24px 24px;
    box-shadow: none;
    width: inherit;
    thead tr th {
      padding-left: 16px;
    }
    tbody > tr > :nth-child(3) {
      padding: 16px;
      word-break: break-word;
      width: 50px;
    }
  }
  .view-all p {
    margin: 0 0 8px 0;
  }
  .view-all p span,
  .new-org p {
    font-weight: bold;
    margin: 0 0 24px 24px;
    padding: 5px 8px;
    min-width: 200px;
  }
  .new-org {
    p.radio {
      margin: 0;
      padding: 16px 0 0 0;
      min-width: auto;
    }
    textarea {
      width: 300px;
      height: 100px !important;
      padding: 8px;
      margin: 0 0 24px 0;
    }
  }
  .view-all h2,
  .new-org h2 {
    font-size: 24px;
    margin: 24px 0 24px 24px;
    font-weight: bold;
  }
  .view-all h3,
  .new-org h3 {
    font-size: 24px;
    margin: 24px 0 24px 24px;
  }
  .org-list {
    h2 {
      font-size: 24px;
      margin: 24px 0 24px 0;
      font-weight: bold;
    }
    table tbody > tr > :nth-child(3) {
      padding: 16px;
    }
  }
}
