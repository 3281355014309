@import "../../css/vars";

.included-course-details {
  height: 98px;
  transition: height .3s ease-in-out;
  transition-delay: 0.4s;
  overflow: hidden;
  > div > ul {
    &:first-child {
      margin-bottom: 8px;
      font-size: $copy-body;
      font-weight: bold;
    }
    &:last-child {
      height: 56px;
      border: 1px solid $ui-gray-medium;
      border-radius: 4px;
      background-color: $grey-section-background;
      input {
        margin: 0;
        border: none;
        border-radius: 0;
      }
      li {
        display: flex;
        align-items: center;
        span {
          padding-left: 12px;
        }
      }
      li:first-child {
        border-right: 1px solid $ui-gray-medium;
        justify-content: center;
      }
      li:nth-child(3) {
        border-left: 1px solid $ui-gray-medium;
        border-right: 1px solid $ui-gray-medium;
      }
    }
    li:first-child {
      width: 46px;
    }
    li:nth-child(2) {
      width: 100px;
    }
    li:nth-child(3) {
      width: 269px;
      flex: 1 0 auto;
    }
    li:last-child {
      width: 106px;
    }
  }
  button {
    margin-top: 8px;
    margin-left: 16px;
    padding: 0;
    color: $link-blue;
    font-size: $copy-body;
  }
}

.set-delete {
  height: 0;
  animation: .2s linear 2 flash;
}

@keyframes flash {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}

