@import "../../css/vars";

.course-questions {
  > header {
    h2 {
      margin-bottom: 8px;
    }
    button {
      color: $link-blue;
      border: none;
      background-color: white;
      font-size: $copy-body;
    }
    label[for="passmark"] {
      padding: 16px 16px 16px 24px;
      background-color: #FAF5EA;
      color: $font-color-dark;
      border-radius: 16px;
      input {
        width: 62px;
        height: 40px;
        margin: 0 0 0 16px;
      }
    }
  }

  .base-question {
    padding: 24px;
    height: auto;
    overflow: hidden;
    transition: height 0.2s ease-in-out;
    border-radius: 16px;
    background-color: $grey-section-background;
    header {
      margin-bottom: 20px;
      h3 {
        margin-bottom: 0;
        font-size: 20px!important;
      }
      button {
        color: $main-blue;
        font-weight: bold;
        font-size: 30px;
        img {
          width: 14px;
          height: 14px;
        }
      }
    }
    .base-input,
    .base-input input {
      width: 100%;
    }
    .base-input {
      margin-top: 16px;
      display: inline-block;
    }
    .base-selector {
      position: relative;
      span {
        font-weight: bold!important;
      }
      select {
        width: 100%;
        height: 40px;
        margin-top: 6px;
        padding: 4px 6px 4px 16px;
        border-radius: 4px;
        border: 1px solid $ui-gray-medium;
        font-size: 16px;
        background-color: white;
      }
      img {
        top: 43px;
        right: 14px;
        position: absolute;
      }
    }
    .ql-editor {
      background-color: white;
      font-size: $copy-body;
    }
  }
}
