@import "../../css/vars";

.course-authors {
  h2 {
    margin-bottom: 24px!important;
    color: $extra-dark;
  }
  h3 {
    margin-bottom: 16px;
    font-size: 20px!important;
    color: $font-color-dark;
    font-weight: 500;
  }
  .base-tabs-menu,
  .base-textarea,
  .base-input {
    margin-bottom: 16px;
    display: block;
  }
  .base-input input {
    width: 264px;
  }
  .author-group {
    padding: 16px 24px;
    margin-bottom: 16px;
    border-radius: 16px;
    background-color: $grey-section-background;
  }
  b span {
    font-weight: 100;
  }
}
