@import "css/vars";
@font-face {
  font-family: 'interfaceregular';
  src: url('fonts/if_std_rg-webfont.woff') format('woff'),
    url('fonts/if_std_rg-webfont.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
body, body p, body h1, body h2, body h3, body h4, body h5, body h6, body table, body tr, body td, body th, .MuiTypography-h6, textarea, input {
  font-family: 'interfaceregular', Arial, sans-serif!important;
  color: #5f6163;
}

.main-app {
  // padding-top: 80px; // TODO: remove this after regression testing
}

.loading .MuiCircularProgress-root {
  height: 50px;
  width: 100%;
  position: absolute;
  top: 40%;
  left: 75%;
}
.loadingModules .MuiCircularProgress-root {
  height: 50px;
  width: 100%;
  position: absolute;
  top: 40%;
  left: 20%;
}
a {
  color: $font-color-medium;
  cursor: pointer;
  font-family: "interfaceregular",Arial,sans-serif;
  font-size: 14px;
  &:hover {
    color: $link-blue;
  }
}
textarea, input.MuiInputBase-input {
  font-family: "interfaceregular",Arial,sans-serif;
  font-size: 16px;
}
button.primary-button.Mui-disabled {
  background-color: grey!important;
}
body .MuiRadio-colorSecondary.Mui-checked {
  color: #7e5ec4;
}

html,
body,
div#root {
  height: 100%;
}

/* Moved from homepage.css which was removed as a whole page */
:root {
  --sp-xsm: 8px;
  --sp-sm: 16px;
  --sp-md: 24px;
  --sp-lg: 32px;
  --sp-xl: 48px;
  --sp-xxl: 80px;

  --font-color-link: #2a6ebb;
  --font-color-light: #747678;
  --font-color-medium: #5f6163;
  --font-color-dark: #4a4c4d;
  --font-color-extra-dark: #353637;
  --blue-focus: #4d90fe;
  --bmj-grey-30: #e5e6e6;
  --bmj-grey-50: #d9d9da;
  --bmj-grey-100: #cccdcd;
  --bmj-grey-200: #bfc0c1;
  --bmj-grey-500: #747678;
  --bmj-purple-30: #e7e1f4;
  --bmj-purple-50: #f6f6f6;
  --bmj-purple-100: #d0c4e9;
  --bmj-purple-200: #c4b5e4;
  --bmj-purple-600: #684da1;
  --bmj-yellow-30: #fcefd0;
  --bmj-yellow-100: #f9e0a2;
  --bmj-yellow-300: #f5c95c;
  --bmj-blue-400: #5088c7;
  --bmj-blue-500: #2a6ebb;
  --bmj-blue-800: #15355b;
  --bmj-green-500: #69be28;
  --white: #ffffff;
  --error: #cb2331;
  --ui-grey-background: #f2f2f2;
  --primary-light: #957bce;
  --primary-medium: #7e5ec4;
  --primary-dark: #513c7d;
}
a {
  text-decoration: none;
}
.button-container {
  max-width: 911px;
  margin: 0 auto;
}
.button-container button.primary-button {
  margin: 0;
}
.MuiGrid-root.button-container,
.MuiGrid-grid-sm-12.margin-bottom {
  margin-bottom: 24px;
}
/* BUTTONS */

/* IE HACK FOR TEXT IN BUTTONS LINE-HEIGHT */
_:-ms-fullscreen,
:root .primary-button,
_:-ms-lang(x),
.primary-button,
_:-ms-fullscreen,
:root .secondary-button,
_:-ms-lang(x),
.secondary-button {
  line-height: 32px;
}

/* PRIMARY BUTTON */
.primary-button,
button.primary-button {
  background-color: var(--primary-medium) !important;
}
.primary-button.MuiButton-root,
.primary-button,
.primary-button:focus,
.primary-button:hover,
button.primary-button,
button.primary-button:focus,
button.primary-button:hover {
  min-height: var(--sp-xl);
  position: relative;
  padding: 6px var(--sp-sm) !important;
  color: var(--white) !important;
  top: -1px;
  font-family: 'interfaceregular';
  text-transform: inherit !important;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
  0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.primary-button.MuiButton-root:focus,
.primary-button:focus,
.primary-button:focus:focus,
.primary-button:hover:focus,
button.primary-button:focus,
button.primary-button:focus:focus,
button.primary-button:hover:focus {
  outline: 2px auto -webkit-focus-ring-color !important;
  outline-color: -webkit-focus-ring-color;
  outline: 2px solid var(--blue-focus) !important;
  outline-color: var(--blue-focus);
  outline-style: solid;
  outline-width: 2px !important;
}
.primary-button.MuiButton-root .MuiButton-label,
.primary-button .MuiButton-label,
.primary-button:focus .MuiButton-label,
.primary-button:hover .MuiButton-label,
button.primary-button .MuiButton-label,
button.primary-button:focus .MuiButton-label,
button.primary-button:hover .MuiButton-label {
  font-size: var(--sp-sm) !important;
}
.primary-button.MuiButton-root .MuiButton-label a,
.primary-button .MuiButton-label a,
.primary-button:focus .MuiButton-label a,
.primary-button:hover .MuiButton-label a,
button.primary-button .MuiButton-label a,
button.primary-button:focus .MuiButton-label a,
button.primary-button:hover .MuiButton-label a {
  color: var(--white) !important;
}
.primary-button.MuiButton-root:hover,
.primary-button:hover,
.primary-button:focus:hover,
.primary-button:hover:hover,
button.primary-button:hover,
button.primary-button:focus:hover,
button.primary-button:hover:hover {
  background-color: var(--primary-light) !important;
}
.primary-button.MuiButton-root:active,
.primary-button:active,
.primary-button:focus:active,
.primary-button:hover:active,
button.primary-button:active,
button.primary-button:focus:active,
button.primary-button:hover:active {
  background-color: var(--primary-dark) !important;
}