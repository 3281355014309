@import '../../css/vars.scss';

.pagination {
  color: $font-color-medium;
  display: flex;
  flex-direction: column;
  margin: 50px 0;
  text-align: center;
  padding: 0 0 50px;
  .total-pages {
    padding-bottom: 20px;
    display: block;
  }
  .page-numbers-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    button {
      border: none;
      background-color: transparent;
      height: 30px;
      cursor: pointer;

      &:focus {
        outline: 2px solid $link-blue;
        outline-offset: 0;
      }

      &.left,
      &.right {
        cursor: pointer;
        background: transparent;
        border: none;
        color: $font-color-medium;
        &:disabled {
          color: $ui-gray-light;
          cursor: not-allowed;
        }

        &:focus {
          outline: 2px solid $link-blue;
          padding: revert;
        }

        img {
          top: 2px;
          position: relative;
          padding: 0 5px;
        }
      }
      &.left img {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
      }
      span.page-number-links {
        color: $font-color-medium;
        padding: 10px 15px 10px 14px;
        transition: background-color 0.5s;
        @media only screen and (min-width: $s) {
          &:hover {
            background-color: $ui-grey-background;
            padding: 10px 15px 10px 14px;
          }
        }
        &.active {
          color: $link-blue;
          background-color: $ui-grey-background;
          padding: 10px 15px 10px 14px;
          font-weight: bold;
        }
      }
    }
  }
}
