@import '../../css/vars.scss';

.course-information {
  .base-tabs-menu,
  .base-input,
  .base-textarea {
    margin-top: 16px;
    display: block;
  }
  .other-duration {
    height: 0;
    overflow: hidden;
    transition: height 0.2s ease-in-out;
    &.show-other-duration {
      height: 100px;
      .base-input:first-child {
        margin-right: 16px;
    }
    }
  }
  [name="partnersLogo"] img {
    width: 160px;
    height: initial!important;
  }

/* Hide the default checkbox */
.custom-checkbox input[type="checkbox"] {
  display: none;
}

/* Custom checkbox appearance */
.custom-checkbox span::before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid #BFC0C1;
  margin-top: 10px;
  margin-right: 10px; /* Spacing between checkbox and label text */
  box-sizing: border-box;
  background-color: $white;
}

/* Tick mark when checked */
.custom-checkbox input:checked + span::before {
  content: '\2714'; /* Unicode for the tick mark */
  color:$link-blue; /* Tick color */
  border: 1px solid $link-blue;
  font-size: 15px; /* Adjust font size for the tick */
  text-align: center;  
  line-height: 20px; 
}
.custom-checkbox> span{
  display: flex;
  align-items: end; 
  font-size: 16px;
  font-weight: 200;
  color: $font-color-medium;
}
}
