@import "../../css/vars";
@import "../../css/mixins";

.content-editor {
  h2 {
    margin: 0;
    font-size: 24px;
    color: $extra-dark;
  }
  h3 {
    margin-top: 0;
    font-size: 16px;
  }
  label {
    font-size: 16px;
    font-weight: bold;
    color: $font-color-dark;
  }
  input, textarea {
    margin-top: 8px;
    border: 1px solid $ui-gray-medium;
    border-radius: 4px;
  }
  textarea {
    padding: 16px 16px 0 16px;
    font-size: 16px!important;
  }
}
