.new-accreditation h2 {
  font-size: 24px;
  margin: 24px 0 24px 24px;
  font-weight: bold;
}
.new-accreditation h3 {
  font-size: 24px;
  margin: 24px 0 24px 24px;
  font-weight: normal;
}
.new-accreditation p {
  margin: 0 0 60px 24px;
  padding: 5px 8px;
  min-width: 200px;
}
.new-accreditation .MuiOutlinedInput-root {
  min-width: 220px;
}
.new-accreditation p span {
  font-weight: bold;
}
.new-accreditation textarea {
  width: 200px;
  height: 100px;
  padding: 8px;
}
.new-accreditation .result p {
  margin: 32px 0 0 24px;
  font-weight: bold;
}
