@import "../../css/vars";
@import "../../css/mixins";

.module-edit {
  @media only screen and (min-width: 0) {
    height: 100%;
    padding: 24px;
  	background-color: #FAF5EA;
    section {
      @include content-section;
    }
    h1 {
      margin: 0;
      padding: 0;
    }
    .base-button[name="save"] {
      width: 124px;
    }
  }

  @media only screen and (min-width: 1240px) {
    .left-side-menu {
      left: calc(((100% - 650px) / 2) - 232px - 40px);
    }
  }
}
