@import "vars";
/* GLOBAL */
* {
  box-sizing: border-box;
  font-family: interfaceregular, sans-serif;
}
ul { list-style-type: none; }
ul, li { margin: 0; padding: 0; }

button { cursor: pointer; }

.blue-btn {
  height: 48px;
  background-color: $primary-500;
  color: white;
  padding: 8px 24px;
  border-radius: 4px;
  text-align: center;
  &[disabled] {
    cursor: default;
  }
}

.base-action-btns {
  padding-left: 0;
  color: $link-blue;
  font-size: $copy-body;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}


/* primer multiselect custom rules */
.p-multiselect-header .p-checkbox,
.p-multiselect-close {
  display: none;
}
.p-multiselect-filter-container {
  margin-left: 0!important;
}
.p-multiselect-items-wrapper {
  max-height: 340px!important;
}

@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}
