$ui-grey-background: #f2f2f2;
$main-blue: #2A6EBB;
$purple: #7e61c1;
$light-purple: #F3EEFE;
$pale-blue-grey: #C2D1D9;
$light-dimmed-blue-gray: #F2F5F7;
$almost-black: #444444;
$font-color-light: #747678;
$font-color-medium: #5F6163;
$font-color-dark: #4A4C4D;
$primary-10: #F3F0F9;
$primary-30: #E7E1F4;
$primary-100: #d0c4e9;
$primary-500: #7E5EC4;
$primary-600: #684DA1;
$secondary-500: #2A6EBB;
$ui-gray-light: #D9D9DA;
$ui-gray-medium: #CCCDCD;
$outline-color: rgb(0, 95, 204);
$outline-default: 2px solid $outline-color;
$grey-section-background: #f2f2f2;
$section-desktop-max-width: 1232px;
$link-blue: #2A6EBB;
$dark-gray: #4F4F4F;
$font-dark-blue: #006EBD;
$success-green: #69BE28;
$main-purple: #7D5FC3;
$error-red: #cb2331;
$main-green: #219653;
$extra-dark: #353637;
$sttus-orange: #F0AB00;
$light-gray: #9D9D9D;
$white: #ffffff;

/* font sizes */
$copy-secondary: 14px;
$copy-body: 16px;
$copy-lead: 20px;

/* grid sizes */
$p-xs: 8px;
$p-xs-md: 12px;
$p-s: 16px;
$p-md: 24px;
$p-lg: 32px;
$p-xl: 48px;

$max-content-area: 1192px;
$l-r-content-padding: 24px;

/* BREAKPOINTS */
$xs: 480px;
$s: 600px;
$md: 768px;
$md-lg: 960px;
$lg: 1024px;
$lg-xl: 1280px;
$xl: 1440px;
