@import '../../css/vars';

.module-management-questions {
  header {
    padding: 18px 0;
    h2 {
      margin: 0 !important;
    }
    input {
      width: 66px;
      margin: 0 8px;
      padding: 16px;
      border-radius: 4px;
      border: 1px solid #d9d9da;
    }
  }
}
