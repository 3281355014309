@import "../../css/mixins";

.collection-edit {
  height: 100%;
  padding: 24px;
  background-color: #EAF1F8;
  section {
    @include content-section;
  }
}
