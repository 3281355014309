@import "../../css/vars";

.batch-accreditation {
  padding: 0 $l-r-content-padding;
}
.batch-acc {
  max-width: $max-content-area;
  margin: 0 auto;
}

table {
  table-layout: fixed;
  overflow: hidden;
  width: 200px;
  min-width: 200px;
}
table thead tr, table thead tr th {
  text-align: left;
  padding: 0;
  background-color: #e7e1f4;
  font-weight: bold!important;
}
tr:nth-child(even) {
  background-color: #f2f2f2;
}
table tbody tr.MuiTableRow-root.MuiTableRow-hover:hover {
  background-color:#e7e1f4;
}
table .padding-left-zero {
  padding-left: 0!important;
}
#tableTitle {
  font-size: 24px!important;
  line-height: 40px!important;
}
.table-container {
  border: 1px solid #e7e1f4;
  margin: 0;
  box-shadow: none;
}
.table-container2 > div:first-child,
.table-container > div:first-child {
  background-color: #7e5ec4;
}
.table-container2 > div:first-child >div:first-child#tableTitle,
.table-container > div:first-child >div:first-child#tableTitle {
  color: #fff!important;
}
.table-container2 {
  border: 1px solid #e7e1f4;
  margin: 0 0 0 24px;
  box-shadow: none;
}
.MuiTableRow-root.Mui-selected td,
.MuiTableRow-root.Mui-selected th,
.MuiTableRow-root.Mui-selected:hover,
.makeStyles-highlight-7,
.makeStyles-highlight-56 {
  background-color: #e7e1f4!important;
}
body table tbody>tr>:nth-child(3),
body table tbody>tr>:nth-child(3) td {
  padding: 0;
  word-break: break-word;
  width:50px;
}
.batch-acc button {
  margin: 24px 0;
}
.batch-acc h2 {
  font-size: 24px;
  margin: 24px 0;
  font-weight: bold;
}
.batch-acc .MuiTableCell-sizeSmall:last-child {
  padding-left: 0;
}
.batch-acc .filter {
  padding: 0 0 24px 24px;
  font-family: 'interfaceregular', Arial, sans-serif!important;
  color: #5f6163!important;
}
.batch-acc .filter h3 {
  display: inline-block;
  margin: 0 24px 0 0;
  font-weight: normal;
  font-size: 18px;
}
.batch-acc .filter input[type="number"] {
  height: 19px;
  font-size: 16px;
  font-family: 'interfaceregular', Arial, sans-serif!important;
  color: #5f6163!important;
}
.batch-acc .result {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
}
.batch-acc .result h3 {
  font-size: 24px;
  margin: 24px 0 0 24px;
}
.MuiTableCell-sizeSmall.MuiTableCell-paddingCheckbox {
  width: 44px!important;
}
.search i {
  right: 10px;
  top: 15px;
}
.search-input {
  padding: 12px 8px;
  border-radius: 4px;
  border: 1px solid $light-gray;
  width: 320px;
  font-size: 16px;
  margin-bottom: 10px;
}
.filter-btn {
  height: 36px;
  font-size: 16px;
  background-color: $white;
  color: $purple;
  border: 1px solid $purple;
  border-radius: 4px;
  padding: 8px 24px;
  text-transform: capitalize;
  margin: 0px !important;
  float: right;
  &[disabled] {
    color: $primary-100;
    border-color: $primary-100;
    cursor: default;
  }
}

.modal-size{
  width: 70%;
}
div.base-modal {
  z-index: 99;
}
.width34 {
  width: 34%;
}
.p-multiselect {
  width: 100%;
}
div#bmj-journals-subscriptions > label > b {
  margin-bottom: 10px;
  display: block;
}