@import '../../css/vars';

.module-question-layout {
  padding-bottom: 24px;
  font-size: 20px;
  p {
    margin-bottom: 2px;
  }
  #chapter-selection {
    margin-bottom: 16px;
    p {
      padding-left: 8px;
      margin-bottom: 12px;
    }
  }
  select {
    width: 100%;
    margin-top: 0;
    padding: 18px 12px;
    border-radius: 4px;
    border-color: $ui-gray-medium;
    border-width: 2px;
    font-size: $p-s;
  }
}
