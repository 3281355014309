@import "../../css/vars";

.status-selector {
  select {
    padding-right: 12px;
    border: none;
    background: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  img {
    margin-left: -8px;
    transform: translateY(-1px);
    pointer-events: none;
  }
  #status-indicator {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: inline-block;
    transform: translate(10px, 3px);
  }
  .arch {
    background-color: $font-color-light;
  }
  .live {
    background-color: $success-green;
  }
  .pend {
    background-color: orange;
  }
}
