@import "../../css/vars";

.base-image-upload {
  label {
    width: 230px;
    margin-top: 8px;
    padding: 24px 50px;
    border: 1px dashed $secondary-500;
    color: $secondary-500;
    font-weight: 100!important;
  }
  input[type="file"] {
    visibility: hidden;
    width: 0;
    height: 0.1px;
  }
  > div,
  .img-preview {
    width: 280px;
  }
  span {
    color: $secondary-500;
  }
}
