@import '../../css/vars';

.main {
  margin: 0 auto;
  .header {
    position: sticky;
    top: 82px;
    height: 70px;
    z-index: 900;
    background-color: white;
    padding: 0 24px;
    > h2 {
      margin: 0;
    }
    .toolbar > button {
      margin-left: 10px;
    }
    .filter-btn {
      height: 36px;
      font-size: 16px;
      background-color: white;
      color: $purple;
      border: 1px solid $purple;
      border-radius: 4px;
      padding: 8px 24px;
      text-transform: capitalize;
      &[disabled] {
        color: $primary-100;
        border-color: $primary-100;
        cursor: default;
      }
    }
  }
  .reviews-header-wrapper {
    position: sticky;
    top: 152px;
    z-index: 900;
    .reviews-header {
      > div {
        display: flex;
        width: 100%;
        height: 100%;
        background-color: $primary-30;
        align-items: center;
        flex-grow: 1;
        padding: 4px 10px;

      }
    }
  }
  .reviews-entries {
    margin-top: 10px;
    .row {
      overflow: hidden;
      > span {
        padding: 4px 10px;
      }
      .course-title,
      .review {
        overflow: hidden;
      }
      .editor-comments {
        padding-left: 10px;
        > button {
          align-items: center;
          text-decoration: underline;
        }
      }
      .status {
        text-transform: uppercase;
        font-weight: 800;
      }
      .course-id a {
        color: $link-blue;
      }
      .reviewer-email {
        overflow: auto;
      }
      .published {
        color: green;
      }
      .flagged {
        color: red;
      }
      .unpublished {
        color: grey;
      }
      .deleted {
        color: purple;
      }
    }
    .selected-row {
      background-color: $light-purple;
      border-bottom: 1px solid white !important;
    }
  }
  .reviews-header-wrapper .reviews-header,
  .reviews-entries .row {
    display: grid;
    grid-template-columns:
      40px // checkbox
      100px // date added
      115px // status
      100px // course id
      2.5fr // course title
      3.5fr // review
      90px // editor comments
      1fr // reviewer ics
      2fr; // reviewer email
    align-items: center;
    font-size: 15px;
    min-height: 50px;
    margin: 0 24px;
    input[type='checkbox'] {
      -webkit-appearance: none;
      appearance: none;
      background-color: white;
      margin: 0;
      width: 18px;
      height: 18px;
      border: 1px solid $dark-gray;
      border-radius: 2px;
      display: grid;
      place-content: center;
    }
    input[type='checkbox']::before {
      content: '';
      width: 10px;
      height: 10px;
      transform: scale(0);
      transform-origin: center;
      clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 15px 15px $link-blue;
    }
    input[type='checkbox']:checked::before {
      transform: scale(1);
    }
    input[type='checkbox']:hover {
      cursor: pointer;
      border: 1.75px solid $link-blue;
    }
  }
  .reviews-header-wrapper .reviews-header {
    font-weight: 800;
  }
  .reviews-entries .row {
    border-bottom: 1px solid $light-purple;
  }
}
.loading {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
.blue-btn {
  height: 36px;
  font-size: 16px;
  border-width: 0;
  &[disabled] {
    background-color: $primary-100;
  }
}
.no-reviews {
  margin: 20px auto;
  text-align: center;
}

.modal {
  .modal-header {
    display: flex;
    justify-content: end;
    padding: 10px;
    > button {
      background-color: white;
      border-width: 0;
    }
  }
  background-color: white;
  .modal-body {
    margin: 0 40px;
    .modal-body-comments {
      width: 500px;

      > h3 {
        margin: 20px 0 10px;
      }
      :first-child {
        margin-top: 0;
      }
      .editor-comments-box {
        width: 100%;
        height: 150px;
        resize: none;
        padding: 5px;
        margin-top: 5px;
      }
    }
    .modal-body-filter {
      width: 500px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .filter-selector {
        display: flex;
        flex-direction: column;
        input {
          margin-top: 5px;
          padding: 11px;
          width: 120px;
          border: 1px solid;
          border-radius: 3px;
        }
        .select-status {
          margin-top: 5px;
          padding: 10px;
          border: 1px solid;
          border-radius: 3px;
        }
        select {
          appearance: auto;
          font-size: 16px;
          color: $font-color-medium;
          border: 0 solid $font-color-medium;
          outline: 0;
          width: 130px;
        }
      }
    }
    .modal-body-msg {
      margin-top: 40px;
    }
  }
  .modal-footer {
    display: flex;
    justify-content: center;
    margin: 40px 0 20px;
    > button:nth-child(2) {
      margin-left: 80px;
    }
  }
}
.server-error-modal {
  margin-top: 20px;
  .actions {
    button {
      background-color: $primary-500;
      color: white;
      padding: 14px;
      font-size: 16px;
      border-width: 0;
      border-radius: 5px;
    }
  }
}