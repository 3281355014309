@import "../../css/vars";

.base-tabs-menu {
  button {
    height: 48px;
    background-color: white;
    border-top: 1px solid $light-gray;
    border-bottom: 1px solid $light-gray;
    border-left: 1px solid $light-gray;
    color: $font-color-medium;
    font-size: $copy-body;
    &:last-child {
      border-right: 1px solid $light-gray;
    }
  }
  .tab-selected {
    border: 1px solid $primary-600;
    background-color: $primary-10;
    font-weight: bold;
    color: $primary-600;
    & + button {
      border-left: none;
    }
  }
}
