@import '../../css/vars';

.module-management-chapters {
  @media only screen and (min-width: 0) {
    margin-bottom: 20px !important;
    .chapters {
      h2 {
        margin-bottom: 24px;
      }

      input {
        height: 46px;
        padding: 12px;
        border-radius: 0;
        border-left: 0 none;
        border: 1px solid #cccdcd;
        border-left: 0;
        width: auto !important;
        margin: 8px 0 0 0 !important;
        &:focus-visible {
          z-index: 1000;
        }
      }
      label {
        display: inline-block;
        margin-bottom: 6px;
      }
      .chapter-details {
        margin: 0 0 24px;
        > div {
          div {
            display: flex;
            flex-direction: column;
            &.mins-title {
              max-width: 58px;
              input {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
              }
            }
            &.title-title {
              flex: 1;
            }
            b {
              color: $font-color-dark;
            }
            span {
              padding: 0 16px;
              background: #f2f2f2;
              border: 1px solid #cccdcd !important;
              border-right: none;
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;
              margin-top: 8px;
              height: 46px;
            }
          }
        }
      }
    }
  }
}
