@import "../../css/vars";
.box-radio-selector {
  .radio-box {
    padding: 6px 48px;
    display: inline-block;
    border-left: 2px solid lightgray;
    border-top: 2px solid lightgray;
    border-bottom: 2px solid lightgray;
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
    &:last-child {
      border-right: 2px solid lightgray;
    }
    &.selected {
      border-color: black;
      color: $font-color-dark;
      font-weight: bold;
      border-right: 2px solid $font-color-dark;
      background-color: $grey-section-background;
      & + span {
        border-left: none;
      }
    }
  }
}
