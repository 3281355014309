@mixin white-rounded-box () {
  background: #FFFFFF;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

@mixin content-section () {
  @media only screen and (min-width: 0) {
    max-width: 650px;
    margin: 0 0 40px 250px;
    padding: 24px 32px;
    @include white-rounded-box;
  }
  @media only screen and (min-width: 1240px) {
    margin: 0 auto 40px;
  }
}
