@import '../../css/vars';

.course-learning-objectives {
  .content-wrapper {
    h2 {
      color: $extra-dark;
      line-height: 32px;
      margin-bottom: 0;
    }
    p {
      color: $font-color-dark;
      display: inline-block;
      margin-top: 8px;
      margin-bottom: 24px;
    }
    .course-objective {
      > div {
        width: 100%;
        display: flex;
        justify-content: space-between;
        span {
          color: $font-color-dark;
          font-weight: 700;
          font-size: 16px;
        }
        button {
          transform: translateY(2px);
          color: $link-blue;
          background: transparent;
          border: none;
          font-size: 16px;
          padding: 0;
        }
      }
      textarea {
        padding: 12px;
        margin-bottom: 16px;
        resize: vertical;
      }
    }
    > div > button {
      color: $link-blue;
      background: transparent;
      border: none;
      font-size: 16px;
      padding: 0;
      img.plus-icon {
        position: relative;
        top: 2px;
        margin: 0 8px 0 0;
      }
    }
  }
}
