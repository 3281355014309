@import "../../css/vars";
.confirmation-popup {
  animation-name: fadeIn;
  animation-duration: 200ms;
  @media only screen and (min-width: 0) {
    min-width: 400px;
    padding: 25px 40px 32px;
  	background-color: white;
    border-radius: 8px;
    p {
      margin: 0;
      text-align: left;
      font-size: 20px;
      line-height: 32px;
      span {
        font-size: 16px;
      }
    }
    button {
      width: 136px;
      height: 48px;
      padding: 8px 12px;
      margin-top: 32px;
      font-size: 16px;
      border-radius: 4px;
      transition: background-color 100ms ease-in-out;
    }
    .actions button:first-child {
      margin-right: 16px;
      background-color: $light-purple;
      color: #654B9D;
      &:hover { background-color: darken($light-purple, 2%); }
    }
    .actions button:last-child {
      background-color: $purple;
      color: white;
      &:hover { background-color: darken($purple, 5%); }
    }
  }
}
